import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';
const Root = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setAuthenticated] = React.useState(() => {
        const token = sessionStorage.getItem('getauthtoken');
        return token !== null;
    });
    const { user, userLoggedIn } = useSelector((state) => ({
        user: state.Auth.user,
        userLoggedIn: state.Auth.userLoggedIn,
    }));

    const getRootUrl = () => {
        let url = '';
        if (sessionStorage.getItem('hyper_user') == null || sessionStorage.getItem('hyper_user') == undefined) {
            return (url = 'home');
        }

        const { token, adminApproved } = JSON.parse(sessionStorage.getItem('hyper_user'));
        console.log('token', token);
        console.log('userlogged in ', userLoggedIn, user);
        if (adminApproved) {
            return (url = 'page-not-accessible');
        }
        if (token) {
            return (url = 'apps/evci/knowledge_base');
        } else {
            return (url = 'home');
        }
        // let url = 'dashboard/ecommerce';
        // if(isAuthenticated===true){
        //     let url = 'apps/evci/discover';
        //   return url;
        // }else{
        //     let url = 'apps/evci/discover';
        //     return url;
        // }
        // let url = 'apps/evci/knowledge_base';

        // return url;
    };

    const url = getRootUrl();
    console.log(url);

    return <Navigate to={`/${url}`} />;
};

export default Root;
