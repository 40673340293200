import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';
import React from 'react';

type PrivateRouteProps = {
    component: React.ComponentType,
    roles?: string,
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const api = new APICore();
    const loggedInUser = api.getLoggedInUser();
    const navigate = useNavigate();
    /**
     * not logged in so redirect to login page with the return url
     */
    if (api.isUserAuthenticated() === false) {
        return <Navigate to={'/account/login'} />;
    }
    const { token, adminApproved } = JSON.parse(sessionStorage.getItem('hyper_user'));

    if (!adminApproved || adminApproved == 'false') {
        return <Navigate to={'/page-not-accessible'} />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;
